import React from "react"
import { Helmet } from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import "./layout.css"

export default function Layout({ children }) {
  return (
  <React.Fragment>
        <Helmet>
          <title>COVIDTEST.is</title>
          <link rel="preconnect" href="https://fonts.googleapis.com"/>
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
          <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap" rel="stylesheet"/>
        </Helmet>
        <header>
            <div class="language"><a href="https://hradprof.is" class="language"><StaticImage src="../images/flag_is.png" alt="Icelandic version"/></a></div>
            <div class="book"><a href="book_en.html"><img src="booknow.svg" alt="Book appointment"/></a></div>    
            <div class="logo"><a href="/"><img src="covid_logo.svg" alt="logo"/></a></div>
        </header>
        <aside><a href="book_en.html">
            <StaticImage src="../images/virusbook.svg" alt="book appointment"/>
        </a>
        </aside>
        <div>{children}</div>
        <footer>
            <div>
                <div class="arcticlogo">
                    <StaticImage src="../images/logo.png" alt="Arctic Therapeutics logo" width={120}/>
                </div>
                <div class="locations">
                    <div><b>Locations</b></div>
                    <div>
                        Kleppsmýrarvegur 8<br/>
                        104 Reykjavík<br/>  
                        <a href="mailto:info@covidtest.is">info@covidtest.is</a>
                    <br/>+354 869-9080</div>
                    <div class="location-spacer"></div><div>
                        Hvannavellir 10<br/>
                        600 Akureyri<br/>
                        <a href="mailto:akureyri@covidtest.is">akureyri@covidtest.is</a><br/>
                        +354 888-9412
                    </div>                    
                   <div class="location-spacer"></div><div>
                        Harpa<br/>
                        Austurbakki 2<br/>
                        101 Reykjavík<br/>  
                        <a href="mailto:info@covidtest.is">info@covidtest.is</a>   
</div>                </div>
                <nav>
                    <a href="tests_en.html">Our Tests</a> | 
                    <a href="cancellations_en.html">Cancellation Policy</a> |
                    <a href="toc_en.html">Terms & Conditions</a> | 
                    <a href="mailto:info@covidtest.is">Contact Us</a>
                </nav>  
            </div>
        </footer>
  </React.Fragment> 
  )
}