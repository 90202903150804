import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const emphasisStyle = {
  fontWeight: 400,
};


// markup
const IndexPage = () => {
  return (
    <Layout>
      <main class="content">
        <title>COVIDTEST.is</title>
            <h2>GET YOUR COVID TRAVEL CERTIFICATE NOW</h2>
            <h3>
                GET TESTED <span class="free">FREE</span> OF CHARGE<br/>
                FOR ANY OCCASION
            </h3>
            <h4>
                Certified by the Icelandic Ministry of Health<br/>
                EU Digital COVID Certificate compliant<br/>
                Fulfills CDC requirements for travel to USA<br/>
                Group testing available<br/>
            </h4>
            <p>
                Choose your convenient time and book a Covid-19 Rapid Test in Reykjavik or Akureyri. A new government regulation issued by the Icelandic Ministry of Health now covers COVIDTEST.is test costs for all of our customers for any occasion.
                <br/><br/>
                You can choose a location, date and timeslot for your Rapid Antigen Test in our booking system. You can book for your entire family/group at the same time.
                <br/><br/>
                Sample collection takes only 15 seconds, after which you can get back to enjoying your trip. Customers generally receive their electronic certificate within an hour. 
                <br/><br/>
                <b>Locations:</b>
                <br/>
                <b>Reykjavík - Kleppsmýrarvegur 8</b>. Located by one of the main roads just 5 minutes from city center. Plenty of parking and good access for busses.<br/>
                Opening hours: 08:15 - 16:00 weekdays and Saturdays from 09:00 – 16:00.                <br/>
                <span style={emphasisStyle}>Closed between 12:00 and 13:00 on Weekdays and 12:00 and 12:45 on Saturdays</span>
                <br/><br/>
                <b>Akureyri - Hvannavellir 10</b> (The unofficial capital of the north). <br/>
                Opening hours: 08:00  - 16:00 Mondays through Wednesdays, 08:00 - 18:00 on Thursdays, 08:00 - 20:00 on Fridays, 10:00 - 16:00 on Saturdays, and 10:00 - 14:00 on Sundays.<br/>
                <span style={emphasisStyle}>Closed between 12:00 and 13:00 on Weekdays.</span>
                <br/><br/>
                <b>Reykjavík - Harpa - K1</b>. COVIDTEST.is has now opened a testing location in Iceland's most famous concert hall.<br/>
                Opening hours: 10:00 AM - 18:00 PM weekdays and Saturdays. 13:00 - 18:00 Sundays.
                <br/>
                <span style={emphasisStyle}>Closed between 12:00 and 12:45 on Weekdays and Saturdays</span>
                <br/><br/>
                You simply choose the location and time that suits you best in our booking system. Your confirmation email will contain your electronic booking ticket, a map of the booked location along with other important information about your booking that is important to read.  
                <br/><br/>
                COVIDTEST.is is a privately run company which provides great service and fast results.
            </p>    
            <hr/>
            <h2>WHAT WILL MY CERTIFICATE LOOK LIKE?</h2>
            COVIDTEST.is is operated under Arctic Therapeutics which is authorized to perform Covid Rapid Tests and issue certificates by the Icelandic Ministry of Health. 
            <br/><br/>
            <div class="img-wrapper">
                <StaticImage src="../images/sample_cert.png" title="Sample Certificate" alt="Sample Certificate"/>
            </div>
            <hr/>
            <h2>Frequently asked questions</h2>
            <details>
                <summary>Is it possible to book a test on the same day?</summary>
                <p>
                    Yes, when you click book my appointment you will see all available times. If the time you need is not available, we will try to make an exception if it is urgent, you can book an appointment for the next available slot and then show up at our testing center in Reykjavík. Make sure to let us know when you show up so that we can find your booking in our booking system.  
                </p>
              </details>                   
              <details>
                <summary>I am considering booking a test. Before I sign up, I want to confirm that the LumiraDx Rapid Antigen Test is accepted for travel back to my country? </summary>
                <p>
                    The LumaraDx Rapid Antigen Test is a rapid antigen test which are currently accepted in the US, UK and most of the Nordics. You are responsible for verifying that Rapid Antigen tests are accepted in your country at the time of travel per our <a href="toc_en.html">terms and conditions</a>. 
                </p>
              </details>               
              <details>
                <summary>Is it a problem to show up earlier to my test, instead of 12 am I would to come at 10 am?</summary>
                <p>
                    We generally ask that you show up at your booked time, however there is no problem showing up later or earlier time than you booked your test in Reykjavik if something in your schedule changes. Just make sure to come during our opening hours. 
                </p>
              </details>                 
              <details>
                <summary>Can I change my booking date?</summary>
                <p>
                    No problem, just send us your details and the new booking date at <a href="mailto:info@covidtest.is">info@covidtest.is</a>.
                </p>
              </details>                    
              <details>
                <summary>I haven’t received my result from my test?</summary>
                <p>
                    Customers generally receive their test results well within an hour. If we have big groups that day, the tests can delay by couple of hours. However, we try our best to do all the test as soon as possible.  
                    <br/><br/>
                    Please also check your <u>junk or spam email</u>. If you have not had your result within 3-4 hours, please contact us at <a href="mailto:info@covidtest.is">info@covidtest.is</a>. 
                </p>
              </details>   
              <details>
                  <summary>Is a rapid test the same as a PCR test?</summary>
                  <p>                  
                    No, we offer Rapid Antigen tests which are not the same as PCR tests.
                    <br/><br/>
                    A rapid antigen test detects protein fragments specific to the Coronavirus and has a very quick turnaround time for results as processing time only takes around 15 minutes.
                    <br/><br/>
                    A PCR test actually detects RNA (or genetic material) that is specific to the virus. Turnaround time is longer, generally within a couple of days, but results can be within 24 hours. These tests are only offered by the national healthcare system in Iceland.
                    <br/><br/>
                    Make sure the country you are travelling to accepts COVID-19 Rapid Antigen tests. Some countries might demand a negative PCR test.
                  </p>
              </details>                    
      </main>
    </Layout>
  )
}

export default IndexPage
